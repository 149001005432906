<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col
          :cols="espacoExtra ? 9 :12"
        >
          <h4>{{ titulo }}</h4>
          <fieldset
            v-if="descricao"
            class="font-weight-bold text-muted small"
          >
            {{ descricao }}
          </fieldset>
        </b-col>
        <b-col
          v-if="espacoExtra"
          cols="3"
        >
          <slot name="valor-extra" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr
            class="mx-0 mt-0 mb-2"
            style="border-top: 1px solid #a3a4a66b !important;"
          >
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'DivisoriaDescricao',
  props: {
    titulo: {
      type: String,
      required: true,
    },
    descricao: {
      type: String,
      default: null,
    },
    espacoExtra: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
